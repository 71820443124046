var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.toggle.ppjkServiceDetail
    ? _c(
        "div",
        { staticClass: "px-3 border-bottom" },
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                "label-col": _vm.labelCol,
                label: _vm.$t("lbl_gross_weight_kgs"),
                prop: "grossWeight"
              }
            },
            [
              _c("a-input-number", {
                staticStyle: { width: "250px" },
                attrs: {
                  value: _vm.form.grossWeight,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "grossWeight")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                "label-col": _vm.labelCol,
                label: _vm.$t("lbl_price_kgs"),
                prop: "price"
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "250px" },
                attrs: {
                  value: _vm.form.price,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "price")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                "label-col": _vm.labelCol,
                label: _vm.$t("lbl_pph_23"),
                prop: "pph23"
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "250px" },
                attrs: {
                  value: _vm.form.pph23,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "pph23")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                "label-col": _vm.labelCol,
                label: _vm.$t("lbl_total_services"),
                prop: "totalService"
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.totalService)))
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }