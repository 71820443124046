









































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { formatterNumber, reverseFormatNumber, formatCurrency } from "@/validator/globalvalidator";
import { DECIMAL_PLACES_QTY, DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import { debounceProcess } from "@/helpers/debounce";
import { CURRENCY_CODE } from "@enum/global.enum";
import { IImportCostCreate } from "@/models/interface-v2/import-cost.interface";
import { GR_STATUS } from "@/models/enums/good-receipt.enum";

export default Vue.extend({
  name: "ImportCostPPJKServiceDetail",
  data() {
    this.commitState = debounceProcess(this.commitState, 200);
    return {
      CURRENCY_CODE,
      DECIMAL_PLACES_QTY,
      DECIMAL_PLACES_CURRENCY,
      labelCol: { span: 4 },
    };
  },
  computed: {
    ...mapState({
      form: (st: any) => st.importCostStore.form as IImportCostCreate,
      toggle: (st: any) => st.importCostStore.toggleSection as boolean,
    }),
    ...mapGetters({
      getGRStatus: "importCostStore/GET_STATUS_GOOD_RECEIPT",
    }),
    isWaitForApproval(): boolean {
      return this.getGRStatus === GR_STATUS.WAIT_FOR_APPROVAL;
    },
    isSubmitted(): boolean {
      return this.getGRStatus === GR_STATUS.SUBMITTED;
    },
    isUnbilled(): boolean {
      return this.getGRStatus === GR_STATUS.UNBILLED;
    },
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    formatCurrency,
    ...mapMutations({
      setForm: "importCostStore/SET_FORM",
    }),
    ...mapActions({
      recalculateAll: "importCostStore/RECALCULATE_ALL",
    }),
    commitState(value, field): void {
      this.setForm({
        ...this.form,
        [field]: value,
      });

      this.recalculateAll();
    },
  }
});

